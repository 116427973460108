@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $background-black;
	color: $text-white;

	display: flex;
	justify-content: space-between;
	align-items: center;

	position: sticky;
	top: 0;
	left: 0;
	right: 0;

	z-index: 1000;

	padding: $spacing-sm $spacing-lg;

	max-width: 1440px;
	margin: 0 auto;

	box-sizing: content-box;

	@include sm-down {
		display: none;
	}
}

.container svg {
	max-width: 5rem;
	max-height: 2rem;
}
